<!--
 * @Descripttion: 
 * @version: 
 * @Author: congsir
 * @Date: 2023-03-10 16:54:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-08 09:35:49
-->
<template>
    <div class="flex h-full min-h-0 flex-col ">
        <div class="flex h-full w-full flex-1 items-start border-white/20 ">
            <nav class="flex h-full flex-1 flex-col space-y-1 p-2">
                <button @click.prevent.stop="startNewSession"
                    class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm mb-2 flex-shrink-0 border border-white/20"><span
                        role="img" class="semi-icon semi-icon-default"><svg stroke="currentColor" fill="none"
                            stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round"
                            class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg></span>开始新对话</button>
                <div class="flex-col flex-1 overflow-y-auto border-b border-white/20 hidden-scroll-bar">
                    <div class="flex flex-col gap-2 text-gray-100 text-sm">

                        <template v-for="(item, index) in msgList" :key="index">

                            <button @click.prevent.stop="handleClick(index)"
                                class="flex py-3 px-3 items-center gap-3 relative rounded-md hover:bg-[#1e293b] cursor-pointer break-all group slide-button-list" :class="item.id==currentSessionId?'active bg-[#1e293b]':''"><span
                                    role="img" class="semi-icon semi-icon-default"><svg stroke="currentColor" fill="none"
                                        stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round"
                                        class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                    </svg></span>
                                <div class="flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative text-left">
                                    {{ item['title'] }}
                                </div>
                                <span role="img" @click.prevent.stop="removeCurrent(index)"
                                    class="semi-icon semi-icon-default remove-icon"><svg stroke="currentColor" fill="none"
                                        stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round"
                                        class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <polyline points="3 6 5 6 21 6"></polyline>
                                        <path
                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                        </path>
                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg></span>
                        </button>


                    </template>

                    <!-- <button
                                class="flex py-3 px-3 items-center gap-3 relative rounded-md hover:bg-[#1e293b] cursor-pointer break-all group"><span
                                    role="img" class="semi-icon semi-icon-default"><svg stroke="currentColor" fill="none"
                                        stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round"
                                        class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                    </svg></span>
                                <div class="flex-1 text-ellipsis max-h-5 overflow-hidden break-all relative text-left">新对话</div>
                            </button> -->
                    </div>
                </div>
                <button
                    class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-[12px]"
                    aria-expanded="false" aria-haspopup="dialog" tabindex="0" aria-controls="xjksykc" data-popupid="xjksykc"
                    @click.stop.prevent="removeCurrent(-1)"><span role="img" class="semi-icon semi-icon-default"><svg
                            stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                            stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                            xmlns="http://www.w3.org/2000/svg">
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg></span>清除所有会话</button>



                <!-- <button
                    class="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-[12px]"><span
                        role="img" class="semi-icon semi-icon-default"><svg width="14px" height="14px" viewBox="0 0 14 14"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>查看</title>
                            <defs>
                                <rect id="path-1" x="0" y="0" width="14" height="14"></rect>
                            </defs>
                            <g id="\u9875\u9762-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="PC-1-\u9996\u9875" transform="translate(-20.000000, -991.000000)">
                                    <g id="\u7F16\u7EC4-2" transform="translate(0.000000, 979.000000)">
                                        <g id="\u7F16\u7EC4" transform="translate(20.000000, 12.000000)">
                                            <mask id="mask-2" fill="white">
                                                <use xlink:href="#path-1"></use>
                                            </mask>
                                            <g id="\u77E9\u5F62"></g>
                                            <g id="key" mask="url(#mask-2)" fill="#FFFFFF" fill-rule="nonzero">
                                                <g transform="translate(1.000000, 1.000000)" id="\u5F62\u72B6">
                                                    <path
                                                        d="M4.22477815,5.77577514 C3.86093785,5.17469534 3.71540173,4.50044061 3.71540173,3.75039756 C3.70581069,2.75293573 4.08638976,1.79343873 4.77142264,1.08801022 C5.45645551,0.382581713 6.3883392,-0.00946027876 7.35718926,0.000182293978 C8.3261853,-0.00969580481 9.2582886,0.382242184 9.94350586,1.08769666 C10.6287231,1.79315113 11.0094177,2.75278318 10.9998229,3.75039756 C11.0091889,4.74786165 10.6283933,5.7072676 9.94320129,6.41253224 C9.25800925,7.11779689 8.32603713,7.50961598 7.35718926,7.49974169 C6.55589447,7.49974169 5.90098193,7.27499011 5.24522326,6.82461583 L3.56986561,8.54945351 L4.37116041,9.37528488 L3.35071529,10.4249967 L2.5494205,9.60003646 L2.04004408,10.1253279 L2.84133888,10.9502882 L1.82089377,12 L0,10.1253279 L4.22477815,5.77490401 L4.22477815,5.77577514 Z M7.35718926,1.5002684 C6.11928611,1.5002684 5.1724552,2.47593416 5.1724552,3.75039756 C5.1724552,5.02486095 6.11928611,5.99965558 7.35718926,5.99965558 C8.59593855,5.99965558 9.54276947,5.02486095 9.54276947,3.75039756 C9.54276947,2.47506303 8.52317049,1.5002684 7.35718926,1.5002684 Z">
                                                    </path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg></span>
                    <div>查看API Key</div>
                    <div class="flex items-center bg-[#1F3A5E] px-2 py-2 rounded"><span role="img"
                            class="semi-icon semi-icon-default"><svg width="14px" height="14px" viewBox="0 0 14 14"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <title>对勾</title>
                            <g id="\u9875\u9762-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="PC-1-\u9996\u9875" transform="translate(-127.000000, -991.000000)"
                                    fill-rule="nonzero">
                                    <g id="\u7F16\u7EC4-2" transform="translate(0.000000, 979.000000)">
                                        <g id="check" transform="translate(127.000000, 12.000000)">
                                            <rect id="\u77E9\u5F62" fill="#000000" opacity="0" x="0" y="0" width="14"
                                                height="14"></rect>
                                            <path
                                                d="M12.8919431,2 L11.949701,2 C11.8175984,2 11.6922358,2.06290285 11.6113566,2.17053662 L6.05361126,9.47146074 L3.38864339,5.96986876 C3.30641626,5.86223499 3.18240157,5.79933214 3.05029896,5.79933214 L2.10805691,5.79933214 C2.01774186,5.79933214 1.96786638,5.90696591 2.0231338,5.97965365 L5.71526684,10.8301623 C5.88780901,11.0566126 6.21941352,11.0566126 6.39330368,10.8301623 L12.9768662,2.17892366 C13.0321336,2.10763377 12.9822581,2 12.8919431,2 Z"
                                                id="\u8DEF\u5F84" fill="#417EFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg></span><span>（****74DA）</span></div>
            </button> -->

        </nav>


        <!-- windicss gorup hover -->
        

    </div>
</div></template>

<script>
export default {
    name: "slideBar",
    props: {
        msgList: Array,
        currentSessionId: String,
    },
    setup(props, { emit }) {

        const handleClick = (index) => {
            emit("toggleClick", index);
        }
        const startNewSession = (item) => {
            console.info(item);
            emit('newSession');
        }
        const removeCurrent = (index) => {
            emit('removeCurrent', index);
        }


        /* 冒泡排序 */

        return { handleClick, startNewSession, removeCurrent }
    }
}
</script>


<style lang="scss">
    .remove-icon{
        display: none;
    }

    .slide-button-list:hover .remove-icon{
        display: inline;
    }
    .slide-button-list.active .remove-icon{
        display: inline;
    }
</style>