/*
 * @Descripttion: 
 * @version: 
 * @Author: congsir
 * @Date: 2023-03-23 12:48:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-10 16:43:45
 */
// openaiUrl
// export const openaiUrl= 'https://api.openai.com/v1/chat/completions';
// export const openaiUrl= 'https://api.forchange.cn/';
// export const openaiUrl= 'http://156.247.11.189:10855/stream';
// export const openaiUrl= 'http://chatapi.huazhi.cloud:10855/stream';
export const openaiUrl= 'https://video.huazhi.cloud/gpt/backend-api/conversation';

export const freeOpenaiUrl= "https://video.huazhi.cloud/gpt/backend-api/conversation/stream/free";

export const key = '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';
// export const key = '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';


export const defaultToken = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxODMxMTM0NjcyM0AxNjMuY29tIiwiY3JlYXRlZCI6MTY5OTkzMTk0MjYwNSwiY29tcGFueU5hbWUiOiLlsbHkuJzljY7mmbrnp5HmioDmnInpmZDlhazlj7giLCJuYW1lIjoi6Zuo6L6w5bSU6ZyHIiwiY29tcGFueSI6MiwiZXhwIjoxNzAwMTkxMTQyLCJqdGkiOjI0fQ.u0RGkgaccG9Lvdiqjyhm0HHcK1_OQzZ81z8NTLDz3vNbDSk-C3LvGWi12FERGdUkZCaAMumTBX4ax9R9Bqnasg'

