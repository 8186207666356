

<!--
 * @Descripttion: 
 * @version: 
 * @Author: congsir
 * @Date: 2023-03-08 14:21:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-23 12:53:48
-->
<template>
    <div class="font-sans dark:text-white dark:bg-hex-121212 h-screen" v-if="token">
        <router-view/>
    </div>

    <div class="no-pression" v-else>
        暂无权限使用
    </div>
  
</template>

<script setup>
import {ref, reactive, unref, toRefs, toRaw} from 'vue';

 // import 'highlight.js/styles/monokai.css';
 const getParamsByUrl = (name) => {
     try{
         return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[""])[1].replace(/\+/g,'%20'))||null;

     }catch (e) {
         return null;
     }

 }
 import 'highlight.js/styles/atom-one-dark.css'
 let token  = ref(getParamsByUrl("token")) ;

</script>

<style lang="scss">


@font-face {
    font-family: "Inter var";
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: "Regular";
    src: url("chrome-extension://infppggnoaenmfagbfknfkancpbljcca/Inter-roman-latin.var.woff2") format("woff2")
}

.automa-element-selector {
    direction: ltr
}

[automa-isDragging] {
    user-select: none
}

[automa-el-list] {
    outline: 2px dashed #6366f1;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

// scroll bar style

::-webkit-scrollbar {
    height: 0.5rem;
    width: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(217,217,227,.8);
    border-color: rgba(217,217,227,.8);
    border-radius: 9999px;
    border-width: 1px;
}

.no-pression{
    font-size: 40px;
    color: #2c3e50;
    margin-top: 100px;

}

</style>
